<template>
  <div :class="`${sizeClass} rounded-full ${colorClass}`"></div>
</template>

<script>
export const DEVICE_STATUS = {
  ONLINE: "ONLINE",
  ERRORED: "ERRORED",
  WARNING: "WARNING",
  OFFLINE: "OFFLINE",
};

export default {
  name: "DeviceStatus",
  props: {
    status: {
      type: String,
      required: true,
      validate(status) {
        return Object.values(DEVICE_STATUS).includes(status);
      }
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    colorClass() {
      let color = "bg-gray-500";
      switch (this.status) {
        case DEVICE_STATUS.ONLINE:
          color = "bg-green-500";
          break;
        case DEVICE_STATUS.ERRORED:
          color = "bg-red-500";
          break;
        case DEVICE_STATUS.OFFLINE:
          color = "bg-gray-500";
          break;
        case DEVICE_STATUS.WARNING:
          color = "bg-yellow-500";
          break;
      }
      return color;
    },
    sizeClass() {
      return this.small ? `w-3 h-3` : `w-6 h-6`;
    }
  }
}
</script>

<style scoped>

</style>