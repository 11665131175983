import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './global.css';
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {faCheck,faExclamationCircle, faArrowCircleLeft, faTools, faPrint, faCogs, faSync, faCreditCard, faMoneyBill, faExclamationTriangle, faTimesCircle, faHourglass, faPlus} from '@fortawesome/pro-regular-svg-icons';
import Vuelidate from "vuelidate";
import Toasted from "vue-toasted";
import Keycloak from "keycloak-js";
import LocalAdmin from "@/api/LocalAdmin";
import {createDevServer} from "@/mockServer";
import 'vue-good-table/dist/vue-good-table.css'

if (process.env.VUE_APP_USE_MOCK_API === "true") {
    const worker = createDevServer(process.env.VUE_APP_REMOTE_API_URL);
    worker.start();
}

const initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required'
}

export const keycloak = Keycloak(initOptions);

keycloak.init({onLoad: initOptions.onLoad, checkLoginIframe: false})
    .then((auth) => {
      if (auth) {
        library.add(faCheck,faExclamationCircle, faArrowCircleLeft, faSpinner, faTools, faPrint, faCogs, faSync, faCreditCard, faMoneyBill, faExclamationTriangle, faTimesCircle, faHourglass, faPlus);
        Vue.component('font-awesome-icon', FontAwesomeIcon);
        Vue.use(Vuelidate);
        Vue.use(Toasted);

        Vue.config.productionTip = false;

        new Vue({
          router,
          store,
          render: h => h(App)
        }).$mount('#app');

        createTokenRefresher();
        LocalAdmin.getKioskDetails().then(details => {
            const serialNumber = details.data.serialNumber;
            localStorage.setItem("serialNumber", serialNumber);
            LocalAdmin.loginRecord();
        });
      } else {
        window.location.reload();
      }
    })
    .catch(() => {
      window.location.reload();
    });

const createTokenRefresher = () => {
  setInterval(() => {
    keycloak.updateToken(process.env.VUE_APP_REFRESH_TIME || 70)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Access token refreshed");
          }
        })
        .catch(() => {
          keycloak.logout();
          LocalAdmin.logoutRecord();
        });
  }, process.env.VUE_APP_KEYCLOAK_REFRESH_RATE || 6000)
};

