export default [{
    "content":
        [
        {
            "printLogId":null,
            "printType": "TEST_PRINT",
            "totalCount":1,
            "successCount":0,
            "failed":true,
            "description":"Device Not Found",
            "purchaseId":null,
            "username":"pschultz@tdstickets.com",
            "sessionId":"3e615674-8c02-4547-9944-2901960b4f6d",
            "createDateTime":"2022-04-04T22:35:08.742485Z",
            "failedCount":1
        },
        {
            "printLogId":null,
            "printType":"TEST_PRINT",
            "totalCount":1,
            "successCount":0,
            "failed":true,
            "description":"Device Not Found",
            "purchaseId":null,
            "username":"pschultz@tdstickets.com",
            "sessionId":"3e615674-8c02-4547-9944-2901960b4f6d",
            "createDateTime":"2022-04-04T21:39:27.122776Z",
            "failedCount":1
        },
        {
            "printLogId":null,
            "printType":"TEST_PRINT",
            "totalCount":1,
            "successCount":0,
            "failed":true,
            "description":"Device Not Found",
            "purchaseId":null,
            "username":"lbeutlich@tdstickets.com",
            "sessionId":"00ecfebc-4cd1-45c1-9d8e-328f785e6fae",
            "createDateTime":"2022-04-04T17:16:11.752482Z",
            "failedCount":1
        },
        {
            "printLogId":null,
            "printType":"TEST_PRINT",
            "totalCount":1,
            "successCount":0,
            "failed":true,
            "description":"Device Not Found",
            "purchaseId":null,
            "username":"lbeutlich@tdstickets.com",
            "sessionId":"00ecfebc-4cd1-45c1-9d8e-328f785e6fae",
            "createDateTime":"2022-04-04T17:15:33.796211Z",
            "failedCount":1
        },
        {
            "printLogId":null,
            "printType":"TEST_PRINT",
            "totalCount":1,
            "successCount":0,
            "failed":true,
            "description":"Device Not Found",
            "purchaseId":null,
            "username":"lbeutlich@tdstickets.com",
            "sessionId":"00ecfebc-4cd1-45c1-9d8e-328f785e6fae",
            "createDateTime":"2022-04-04T17:11:04.555295Z",
            "failedCount":1
        },
        {
            "printLogId":null,
            "printType":"BOOKING",
            "totalCount":2,
            "successCount":2,
            "failed":false,
            "description":"Confirmation #42726749",
            "purchaseId":null,
            "username":"service-account-kiosk-admin-service-private",
            "sessionId":"22c9bcbe-82d0-40af-af61-1f16d613b623",
            "createDateTime":"2022-04-01T15:48:32.532276Z",
            "failedCount":0
        },
        {
            "printLogId":null,
            "printType":"REPRINT",
            "totalCount":3,
            "successCount":0,
            "failed":true,
            "description":"Command build error",
            "purchaseId":null,
            "username":"service-account-kiosk-admin-service-private",
            "sessionId":"4350f9de-aeb7-44f6-98ef-fe9b0c7b7cb3",
            "createDateTime":"2022-03-22T21:13:16.449255Z",
            "failedCount":3
        },
        {
            "printLogId":null,
            "printType":"TEST_PRINT",
            "totalCount":1,
            "successCount":0,
            "failed":true,
            "description":"Device Not Found",
            "purchaseId":null,
            "username":"pschultz@tdstickets.com",
            "sessionId":"7ee3ab70-6720-43f6-b111-7c73f35d63de",
            "createDateTime":"2022-03-22T15:25:20.249492Z",
            "failedCount":1
        },
        {
            "printLogId":null,
            "printType":"BOOKING",
            "totalCount":2,
            "successCount":0,
            "failed":true,
            "description":"Command build error",
            "purchaseId":null,
            "username":"service-account-kiosk-admin-service-private",
            "sessionId":"4434a43e-7565-4fa7-b298-008bb5bb2b76",
            "createDateTime":"2022-03-18T16:05:54.698332Z",
            "failedCount":2
        },
        {
            "printLogId":null,
            "printType":"BOOKING",
            "totalCount":3,
            "successCount":0,
            "failed":true,
            "description":"Command build error",
            "purchaseId":null,
            "username":"service-account-kiosk-admin-service-private",
            "sessionId":"7b479ff0-6366-4a24-9a9f-10ffb783fe10",
            "createDateTime":"2022-03-18T15:55:08.950504Z",
            "failedCount":3
        }
        ],
    "pageable":{
        "sort":{
            "empty":false,
            "sorted":true,
            "unsorted":false
            },
        "offset":0,
        "pageNumber":0,
        "pageSize":10,
        "paged":true,
        "unpaged":false
    },
    "totalPages":18,
    "totalElements":175,
    "last":false,"size":10,
    "number":0,
    "sort":{
        "empty":false,
        "sorted":true,
        "unsorted":false
    },
    "numberOfElements":10,
    "first":true,
    "empty":false
}];