import LocalAdmin from "@/api/LocalAdmin";

export default  {
    namespaced: true,
    state: () => ({
        logs: {},
        serverParams: {
            sort: 'createDateTime,desc',
            page: 0,
            size: 10,
        },
        detailCCTransaction:{},
        detailCashTransaction: {}
    }),
    mutations: {
        setTransactionLogsResponse(state, payload) {
            state.logs = payload;
        },
        updateParams(state, payload) {
            state.serverParams = {...state.serverParams, ...payload};
        },
        setTransactionLogCCDetails(state, payload) {
            state.detailCCTransaction = payload;
        },
        setTransactionLogCashDetails(state, payload) {
            state.detailCashTransaction = payload;
        }

    },
    actions: {
        async fetchTransactionLogs({state, commit}) {
            const logs = await LocalAdmin.getTransactionLogs(state.serverParams);
            commit("setTransactionLogsResponse", logs.data);
        },
        async getTransactionLogCCDetails({commit}, id) {
            const details = await LocalAdmin.getTransactionLogCCDetails(id);
            commit("setTransactionLogCCDetails", details.data);
        },
        async getTransactionLogCashDetails({commit}, id) {
            const details = await LocalAdmin.getTransactionLogCashDetails(id);
            commit("setTransactionLogCashDetails" , details.data);
        }
    },
    getters: {
        transactionLogs(state) {
            return state.logs.content;
        },
        totalRows(state) {
            return state.logs.totalElements;
        },
        currentPage(state) {
            return state.serverParams.page;
        }
    },
}
