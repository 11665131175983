export default {
    methods: {
        successToast(message, timeMs = 3000, position= "top-right") {
            this.$toasted.success(message, {
                position: position,
                duration: timeMs
            });
        },
        errorToast(message, timeMs = 3000 , position= "top-right") {
            this.$toasted.error(message, {
                position: position,
                duration: timeMs
            });
        },
        infoToast(message, timeMs = 3000 , position= "top-right") {
            this.$toasted.info(message, {
                position: position,
                duration: timeMs
            });
        }
    }
};
