import LocalAdmin from "@/api/LocalAdmin";

export default  {
    namespaced: true,
    state: () => ({
        responseData: {
            content: [],
            totalElements: 0
        },
        resetDetails: {},
        rejectDetails: {},
        serverParams: {
            sort: 'createDateTime,desc',
            page: 0,
            size: 10,
        },
    }),
    mutations: {
        responseData(state, payload) {
            state.responseData = payload;
        },
        updateParams(state, payload) {
            state.serverParams = {...state.serverParams, ...payload};
        },
        resetDetails(state, payload) {
            state.resetDetails = payload;
        },
        resetRejectDetails(state, payload) {
            state.rejectDetails = payload;
        }
    },
    actions: {
        async fetchLogs({state, commit}) {
            const resp = await LocalAdmin.getDispenseLogs(state.serverParams);
            commit("responseData", resp.data);
        },
        async getDispenseResetDetails({commit}, resetId) {
            const resp = await LocalAdmin.getDispenseResetDetails(resetId);
            commit("resetDetails", resp.data);
        },
        async getDispenserCashRejectResetDetail({ commit },id) {
            const resp = await LocalAdmin.getDispenserCashRejectDetail(id);
            commit("resetRejectDetails", resp.data);
        }
    },
    getters: {
        logs(state) {
            return state.responseData.content.map((obj) => {
                return {
                    id: obj.recordId,
                    event: obj.event,
                    status: obj.status,
                    user: obj.username,
                    dateTime: obj.createDateTime,
                    details: obj.paymentId,
                };
            });
        },
        currentPage(state) {
            return state.serverParams.page;
        },
        totalRows(state) {
            return state.responseData.totalElements;
        },
    },
}