import { kioskZonedDateTime } from "../../../utils/timeUtils.js";
import LocalAdmin from "@/api/LocalAdmin";

export default  {
    namespaced: true,
    state: () => ({
        acceptTotalBefore: {},
        acceptTotalAfter: {},
        rejectTotalBefore: {},
        dispenseTotalBefore: {},
        dispenseTotalAfter: {},
        kioskDetails: {},
        testPrinterSuccess: null,
        testPrinterLoading: null,
        printError: null,
        printResult: {ok: true, message: "Success"},
        createPrinterLogResponse: {},
        API_KEYS_PRINT_LOG: {
            TEST: "TEST_PRINT",
            DISPENSER_RECEIPT: "CASH_DISPENSER_RECEIPT",
            DISPENSER_RECEIPT_NO_UPDATE: "CASH_DISPENSER_RECEIPT_NO_UPDATE",
            ACCEPTOR_RECEIPT: "CASH_ACCEPTOR_RECEIPT",
            ACCEPTOR_RECEIPT_NO_UPDATE:  "CASH_ACCEPTOR_RECEIPT_NO_UPDATE",
            REJECT_RECEIPT: "CASH_DISPENSER_REJECT_RECEIPT",
        }
    }),
    mutations: {
        setRejectedBillsBefore(state, bins) {
            state.rejectTotalBefore = bins;
        },
        setAcceptTotalBefore(state, total) {
            state.acceptTotalBefore = total;
        },
        setAcceptTotalAfter(state, total) {
            state.acceptTotalAfter = total;
        },
        setDispenseTotalBefore(state, total) {
            state.dispenseTotalBefore = total;
        },
        setDispenseTotalAfter(state, total) {
            state.dispenseTotalAfter = total;
        },
        setKioskDetails(state, details) {
            state.kioskDetails = details;
        },
        setTestPrinterLoading(state,payload){
            state.testPrinterLoading = payload;
        },
        setTestPrinterSuccess(state,payload){
            state.testPrinterSuccess = payload;
            setTimeout(() => {
                state.testPrinterSuccess = null;
            },100);
        },
        setPrintError(state, message) {
            state.printError = {};
            state.printError.active = true;
            state.printError.message = message;
            state.printResult = {ok: false, message: message};
            setTimeout(() => {
                state.printError = null;
            },100);
        },
        setCreatePrinterLogResponse(state, payload){
            state.createPrinterLogResponse = payload;
        }
    },
    actions: {
        saveAcceptTotalBefore({ commit }, total) {
            commit("setAcceptTotalBefore", total);
        },
        saveAcceptTotalAfter({ commit }, total) {
            commit("setAcceptTotalAfter", total);
        },
        saveDispenseTotalBefore({ commit }, total) {
            commit("setDispenseTotalBefore", total);
        },
        saveDispenseTotalAfter({ commit }, total) {
            commit("setDispenseTotalAfter", total);
        },
        saveKioskDetails({commit}, details) {
            commit("setKioskDetails", details);
        },
        printReceipt({ state, commit, dispatch }, receiptType) {
            const printer = window.KioCustomSerialDevice;
            let commandArray = [];
            let cutCountMemory;
            let printerBusy = false;
            let intervalId;
            let pollCount = 0;
            let initialPrintLength;
            let finalPrintLength;
            const pollingLimit = 50;
            const pollingMs = 250;
            const cmToMm = 10;
            window.initCuts = (devName, id, cmd, err, data) => {
                try {
                    cutCountMemory = parseInt(data.DataList[0].Data.replace("cuts", ""));
                } catch (e) {
                    commit("setPrintError", "Serial comm error");
                    endPrinting();
                    return;
                }
                if (!cutCountMemory) {
                    commit("setPrintError", "Printing failed");
                    endPrinting();
                    return;
                }
                sendGetPrintedLengthCommand("initLength");
                window.initCuts = null;
            };
            window.printerStatusCheck = (devName, id, cmd, err, data) => {
                let cutCountHardware;
                try {
                    cutCountHardware = parseInt(
                        data.DataList[0].Data.replace("cuts", "")
                    );
                } catch (e) {
                    commit("setPrintError", "Serial comm error");
                    endPrinting();
                    return;
                }
                if (cutCountMemory >= 0) {
                    if (cutCountMemory < cutCountHardware && !printerBusy) {
                        cutCountMemory = cutCountHardware;
                        printerBusy = true;
                        clearInterval(intervalId);
                        commit("setTestPrinterSuccess", true);
                        commit('setTestPrinterLoading', false);
                        window.printerStatusCheck = null;
                        sendGetPrintedLengthCommand("finalLength");
                    } else if (cutCountHardware >= 0) {
                        /*
                        cutCountHardware will be NaN while the printer is printing
                        the first command returned after the coupon is printed will have duplicate data since the buffer has been getting spammed with this status command
                        this conditional ignores the first response and will send the next ticket depending on the second one
                        */
                        printerBusy = false;
                    }
                }
            };
            const pollStatus = () => {
                // if for whatever reason the printer goes offline while printing, this will end the periodic polling
                if (pollCount > pollingLimit) {
                    clearInterval(intervalId);
                    window.printerStatusCheck = null;
                    commit("setTestPrinterSuccess", false);
                    commit('setTestPrinterLoading', false);
                    commit("setPrintError", "Printing failed");
                    sendGetPrintedLengthCommand("finalLength");
                }
                pollCount += 1;
                sendGetCutsCommand("printerStatusCheck");
            };
            const commencePrinting = () => {
                // poll total cuts before printing anything
                printerBusy = true;
                sendGetCutsCommand("initCuts");
            };
            const printTicket = () => {
                printer.WriteBytes(
                    commandArray,
                    true,
                    null,
                    "CUSTOM_PRINTER"
                );
            };
            const enterStandardMode = () => {
                commandArray = commandArray.concat([27, 83]);
            };
            const presentTicket = () => {
                commandArray = commandArray.concat([28, 80, 100, 1, 69, 255]);
            };
            const setPrintDensity = () => {
                commandArray = commandArray.concat([29, 124, 6]);
            };
            const printConfig = () => {
                commandArray = commandArray.concat([31, 240, 0, 25, 29, 86, 0]);
            };
            const reset = () => {
                commandArray = commandArray.concat([27, 64]);
            };
            const sendGetCutsCommand = (callback) => {
                // this command will get status in real time, we are using the callback below to check if a ticket is in the output
                try{
                    printer.WriteBytes(
                        [29, 226],
                        true,
                        callback,
                        "CUSTOM_PRINTER"
                    );
                }catch (e){
                    commit("setPrintError", "Device Not Found");
                    endPrinting()
                }

            };
            window.initLength = (devName, id, cmd, err, data) => {
                try {
                    initialPrintLength = parseInt(data.DataList[0].Data.replace("cm", ""));
                } catch (e) {
                    commit("setPrintError", "Serial comm error");
                    commit("setTestPrinterSuccess", false);
                    endPrinting();
                    return;
                }
                if (!initialPrintLength) {
                    commit("setPrintError", "Printing failed");
                    commit("setTestPrinterSuccess", false);
                    endPrinting();
                    return;
                }
                printTicket();
                intervalId = setInterval(pollStatus, pollingMs);
                window.initLength = null;
            };
            window.finalLength = async (devName, id, cmd, err, data) => {
                try {
                    finalPrintLength = parseInt(data.DataList[0].Data.replace("cm", ""));
                } catch(e) {
                    commit("setPrintError", "Serial comm error");
                }
                if (!finalPrintLength) {
                    commit("setPrintError", "Printing recording failed");
                }
                const record = {
                    paperLengthMm: (finalPrintLength - initialPrintLength) * cmToMm,
                    couponLengthMm: (finalPrintLength - initialPrintLength) * cmToMm,
                };
                try {
                    await LocalAdmin.printRecord(record);
                } catch(e) {
                    commit("setPrintError", "Print recording failed");
                }
                endPrinting();
                window.finalLength = null;
            };
            const endPrinting = () => {
                commit('setTestPrinterLoading', false);
                const request = {
                    printType: receiptType,
                    description: state.printResult.message,
                    totalCount: 1,
                    successCount: state.printResult.ok ? 1 : 0,
                    failed: !state.printResult.ok,
                }
                dispatch("createPrinterLog", request )
            };
            const sendGetPrintedLengthCommand = (callback) => {
                printer.WriteBytes(
                    [29, 227],
                    true,
                    callback,
                    "CUSTOM_PRINTER"
                );
            };
            const mappings = {
                "^FL": "\x1D\x21\x01",
                "^FS": "\x1D\x21\x00",
                "^FW": "\x1D\x21\x11",
            };
            const addLine = (str) => {
                Object.keys(mappings).forEach((key) => {
                    str = str.replaceAll(key, mappings[key]);
                });
                commandArray = commandArray.concat(Array.from(Buffer.from(str)));
                commandArray = commandArray.concat([10]);
            };
            const pad = (str, length, align) => {
                str = str.toString();
                if (str.length > length) {
                    return str.substring(0, length + 1);
                } else {
                    return align === "r" ? str.padStart(length) : str.padEnd(length);
                }
            };
            const date = (str) => {
                return str ? kioskZonedDateTime(str, state.kioskDetails.stopLocation.timeZone) : "NEVER";
            };
            const fl = "^FL";
            const fs = "^FS";
            const kioskID = () => {
                addLine(state.kioskDetails.kioskName);
            };
            const dispenserBinReport = (bins) => {
                bins.forEach((bin) => {
                    addLine(pad(`  ${bin.denominationType}, ${bin.denomination} `,18) + `... Total: $${bin.total.toFixed(2)}`);
                    addLine(pad("  Last reset date:",22) +`${date(bin.lastResetDateTime)}`);
                    addLine(pad("  Last dispense date:", 22) + `${date(bin.lastDispenseDateTime)}`);
                    addLine("");
                });
            };
            const acceptorBinReport = (bins) => {
                bins.forEach((bin) => {
                    addLine(pad(`  CASH, ${bin[0]} `,17) + `... Qty:${bin[1]}`);
                    addLine("");
                });
            };
            const dispenserRejectReport = (bins) => {
                bins.forEach((bin) => {
                    addLine(pad(`  ${bin.denomination}, ${bin.count} `,18) + `... Total: $${bin.total.toFixed(2)}`);
                    addLine("");
                });
            };
            const printDispenserReceiptNoUpdate = () => {
                addLine(fl + "CASH DISPENSER MAINTENANCE RECORD" + fs);
                kioskID();
                addLine("---------------------------------------");
                addLine(`DEVICE LAST DISPENSE DATE: ${date(state.dispenseTotalBefore.lastDispenseDateTime)}`);
                addLine("");
                addLine(fl + "MAINTENANCE INVENTORY:" + fs);
                dispenserBinReport(state.dispenseTotalBefore.bins);
                addLine(`DEVICE LAST RESET DATE: ${date(state.dispenseTotalBefore.lastResetDateTime)}`);
                addLine("");
                addLine(fl + `TOTAL: $${state.dispenseTotalBefore.total.toFixed(2)}` + fs);
                presentTicket();
            };
            const printDispenserReceipt = () => {
                addLine(fl + "CASH DISPENSER MAINTENANCE RECORD" + fs);
                kioskID();
                addLine("---------------------------------------");
                addLine(`LAST DISPENSE DATE: ${date(state.dispenseTotalBefore.lastDispenseDateTime)}`);
                addLine("");
                addLine(fl + "PRE-MAINTENANCE INVENTORY:" + fs);
                dispenserBinReport(state.dispenseTotalBefore.bins);
                addLine(`LAST RESET DATE: ${date(state.dispenseTotalBefore.lastResetDateTime)}`);
                addLine("");
                addLine(fl + `TOTAL: $${state.dispenseTotalBefore.total.toFixed(2)}` + fs);
                addLine("---------------------------------------");
                addLine("");
                addLine(fl + "POST-MAINTENANCE INVENTORY:" + fs);
                dispenserBinReport(state.dispenseTotalAfter.bins);
                addLine(`LAST RESET DATE: ${date(state.dispenseTotalAfter.lastResetDateTime)}`);
                addLine("");
                addLine(fl + `TOTAL: $${state.dispenseTotalAfter.total.toFixed(2)}` + fs);
                addLine("---------------------------------------");
                addLine("");
                addLine("");
                presentTicket();
            };
            const printAcceptorReceipt = () => {
                addLine(fl + "CASH ACCEPTOR MAINTENANCE RECORD" + fs);
                kioskID();
                addLine("---------------------------------------");
                addLine(`LAST ACCEPT DATE: ${date(state.acceptTotalBefore.lastAccepted)}`);
                addLine("");
                addLine(fl + "PRE-MAINTENANCE INVENTORY:" + fs);
                acceptorBinReport(Object.entries(state.acceptTotalBefore.denominationCounts));
                addLine(`LAST RESET DATE: ${date(state.acceptTotalBefore.lastEmptied)}`);
                addLine("");
                addLine(fl + `TOTAL: $${state.acceptTotalBefore.total.toFixed(2)}` + fs);
                addLine("---------------------------------------");
                addLine("");
                addLine(fl + "POST-MAINTENANCE INVENTORY:" + fs);
                acceptorBinReport(Object.entries(state.acceptTotalAfter.denominationCounts));
                addLine(`LAST RESET DATE: ${date(state.acceptTotalAfter.lastEmptied)}`);
                addLine("");
                addLine(fl + `TOTAL: $${state.acceptTotalAfter.total.toFixed(2)}` + fs);
                addLine("---------------------------------------");
                addLine("");
                addLine("");
                addLine("");
                addLine("");
                presentTicket();
            };
            const printAcceptorReceiptNoUpdate = () => {
                addLine(fl + "CASH ACCEPTOR MAINTENANCE RECORD" + fs);
                kioskID();
                addLine("---------------------------------------");
                addLine(`LAST ACCEPT DATE: ${date(state.acceptTotalBefore.lastAccepted)}`);
                addLine("");
                addLine(fl + "MAINTENANCE INVENTORY:" + fs);
                acceptorBinReport(Object.entries(state.acceptTotalBefore.denominationCounts));
                addLine(`LAST RESET DATE: ${date(state.acceptTotalBefore.lastEmptied)}`);
                addLine("");
                addLine(fl + `TOTAL: $${state.acceptTotalBefore.total.toFixed(2)}` + fs);
                presentTicket();
            };

            const printResetReceipt = () => {
                addLine(fl + "CASH DISPENSER REJECTED BILL RESET RECORD" + fs);
                kioskID();
                addLine("---------------------------------------");
                addLine(`LAST REJECT DATE: ${date(state.rejectTotalBefore.lastRejected)}`);
                addLine("");
                addLine(fl + "PRE-MAINTENANCE INVENTORY:" + fs);
                dispenserRejectReport(state.rejectTotalBefore.denominationCounts);
                addLine(`LAST RESET DATE: ${date(state.rejectTotalBefore.lastEmptied)}`);
                addLine("");
                addLine(fl + `TOTAL: $${state.rejectTotalBefore.total.toFixed(2)}` + fs);
                addLine("---------------------------------------");
                addLine("");
                addLine(fl + "POST-MAINTENANCE INVENTORY:" + fs);
                dispenserRejectReport(([
                    {count: 0, denomination: "FIVE", total: 0},
                    {count: 0, denomination: "ONE", total: 0}
                ]));
                addLine("");
                addLine(fl + `TOTAL: $${0.00}` + fs);
                addLine("---------------------------------------");
                addLine("");
                addLine("");
                addLine("");
                addLine("");
                presentTicket();
            };
            const testPrint = () => {
                printConfig();
                presentTicket();
            };
            reset();
            enterStandardMode();
            setPrintDensity();
            if (receiptType === state.API_KEYS_PRINT_LOG.DISPENSER_RECEIPT) {
                printDispenserReceipt();
            } else if (receiptType === state.API_KEYS_PRINT_LOG.DISPENSER_RECEIPT_NO_UPDATE) {
                printDispenserReceiptNoUpdate();
            } else if (receiptType === state.API_KEYS_PRINT_LOG.ACCEPTOR_RECEIPT_NO_UPDATE) {
                printAcceptorReceiptNoUpdate();
            } else if (receiptType === state.API_KEYS_PRINT_LOG.REJECT_RECEIPT) {
                printResetReceipt();
            } else if (receiptType === state.API_KEYS_PRINT_LOG.TEST) {
                commit("setTestPrinterLoading", true);
                commit('setTestPrinterSuccess', null);
                try {
                    testPrint();
                } catch (e) {
                    commit('setTestPrinterLoading', false);
                    commit("setTestPrinterSuccess", false);
                }
            } else {
                printAcceptorReceipt();
            }
            commencePrinting();
            window.printJob = null;
        },
        async createPrinterLog({commit}, request) {
            let res = await LocalAdmin.createPrinterLog( request );
            commit("setCreatePrinterLogResponse", res.data);
        }
    },
    getters: {

    },
}
