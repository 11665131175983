import Vue from 'vue'
import VueRouter from 'vue-router'
import MainMenu from "../views/MainMenu";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainMenu
  },
  {
    path: '/request-demo',
    name: 'RequestDemo',
    component: () => import('../views/RequestDemo.vue')
  },
  {
    path: '/verifone/status',
    name: 'VerifoneStatus',
    component: () => import("../views/verifone/VerifoneStatus")
  },
  {
    path: "/verifone/register",
    name: "VerifoneRegister",
    component: () => import("../views/verifone/VerifoneRegister")
  },
  {
    path: "/cash_machine/dispenser_access",
    name: "DispenserAccess",
    component: () => import("../views/cashMachine/DispenserAccess")
  },
  {
    path: "/cash_machine/dispenser_logs",
    name: "DispenserLogs",
    component: () => import("../views/cashMachine/DispenserLogs")
  },
  {
    path: "/cash_machine/acceptor_access",
    name: "AcceptorStatus",
    component: () => import("../views/cashMachine/AcceptorStatus")
  },
  {
    path: "/general/kiosk-details",
    name: "KioskDetails",
    component: () => import("../views/general/KioskDetails")
  },
  {
    path: "/printer/reset-printer-counter",
    name: "ResetPrinterCounter",
    component: () => import("../views/printer/ResetPrinterCounter")
  },
  {
    path: "/printer/printer-logs",
    name: "PrinterLogs",
    component: () => import("../views/printer/PrinterLogs")
  },
  {
    path: "/general/access-logs",
    name: "AdminAccessLogs",
    component: () => import("../views/general/AdminAccessLogs")
  },
  {
    path: "/general/transaction-logs",
    name: "TransactionLogs",
    component: () => import("../views/general/TransactionLogs")
  },
  {
    path: "/general/transaction-logs/verifone-details/:id",
    name: "VerifoneDetails",
    component: () => import("../views/verifone/VerifoneDetails")
  },
  {
    path: "/general/transaction-logs/cash-details/:id",
    name: "CashTransactionDetail",
    component: () => import("../views/cashMachine/CashTransactionDetail")
  },
  {
    path: "/general/maintenance-logs",
    name: "MaintenanceLogs",
    component: () => import("../views/general/MaintenanceLogs")
  },
  {
    path: "/general/access-logs/detail/:sessionId",
    name: "AdminAccessDetail",
    component: () => import("../views/general/AdminAccessDetail")
  },
  {
    path: "/verifone/admin-logs",
    name: "VerifoneAdminLogs",
    component: () => import("../views/verifone/VerifoneActivityLog"),
  },
  {
    path: "/cash_machine/acceptor-logs",
    name: "AcceptorLogs",
    component: () => import("../views/cashMachine/AcceptorLogs"),
  },
  {
    path: "/cash_machine/dispenser-log-detail/:id",
    name: "DispenserLogDetail",
    component: () => import("../views/cashMachine/DispenserLogDetail"),
  },
  {
    path: "/cash_machine/dispenser-log-reject-detail/:id",
    name: "DispenserLogRejectDetail",
    component: () => import("../views/cashMachine/DispenserLogRejectDetails"),
  },
  {
    path: "/cash_machine/dispenser-log-reset-detail/:id",
    name: "DispenserLogResetDetail",
    component: () => import("../views/cashMachine/DispenserLogResetDetail"),
  },
  {
    path: "/cash_machine/acceptor-logs-detail",
    name: "AcceptorLogDetail",
    component: () => import("../views/cashMachine/AcceptorLogDetail")
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
