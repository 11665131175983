<template>
  <BasicCard title="Credit Card Machine Settings" :icon="['far', 'credit-card']">
    <template slot="card-actions">
      <SpinnerIcon v-if="loading"/>
      <DeviceStatus v-else :status="deviceStatusIcon" />
    </template>
    <template slot="body">
      <div class="grid grid-cols-2 gap-4">
        <BasicButton class="w-full" title="Device Status" @click="goToDeviceStatus" />
        <BasicButton class="w-full" title="CC Machine Event Log" @click="goToCCEventLog" />
      </div>
    </template>
  </BasicCard>
</template>

<script>
import BasicCard from "../Helpers/Cards/BasicCard";
import BasicButton from "../Helpers/Buttons/BasicButton";
import SpinnerIcon from "@/components/Helpers/Loading/SpinnerIcon";
import DeviceStatus, {DEVICE_STATUS} from "@/components/Helpers/DeviceStatus";

export default {
  name: "CreditCardMachineSettingsCard",
  props: {
    deviceConnected: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    SpinnerIcon,
    DeviceStatus,
    BasicCard, BasicButton
  },
  methods: {
    goToDeviceStatus() {
      this.$router.push({name: "VerifoneStatus"});
    },
    goToCCEventLog() {
      this.$router.push({name: "VerifoneAdminLogs"});
    }
  },
  computed: {
    deviceStatusIcon() {
      if (this.deviceConnected) {
        return DEVICE_STATUS.ONLINE;
      } else {
        return DEVICE_STATUS.OFFLINE;
      }
    }
  }
}
</script>
