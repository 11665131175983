export default {
    "total": 245.78,
    "lastResetDateTime": "2022-04-29T14:34:40.988443Z",
    "lastDispenseDateTime": null,
    "bins": [

    {
        "binId": "2",
        "denominationType": "CASH",
        "denomination": "ONE",
        "quantity": 144,
        "total": 144,
        "lastResetDateTime": "2022-04-29T14:34:40.988443Z",
        "lastDispenseDateTime": null
    },
    {
        "binId": "3",
        "denominationType": "COIN",
        "denomination": "QUARTER",
        "quantity": 192,
        "total": 48,
        "lastResetDateTime": "2022-04-29T14:34:40.988443Z",
        "lastDispenseDateTime": null
    },
    {
        "binId": "4",
        "denominationType": "COIN",
        "denomination": "NICKEL",
        "quantity": 179,
        "total": 8.95,
        "lastResetDateTime": "2022-04-29T14:34:40.988443Z",
        "lastDispenseDateTime": null
    },
    {
        "binId": "5",
        "denominationType": "COIN",
        "denomination": "PENNY",
        "quantity": 983,
        "total": 9.83,
        "lastResetDateTime": "2022-04-29T14:34:40.988443Z",
        "lastDispenseDateTime": null
    }
]
}