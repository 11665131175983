export default {
    "event": "BOOKING",
    "createDateTime": "2022-03-28T16:54:22.915Z",
    "confirmationNumber": "42726109",
    "transactionAmount": 33,
    "paymentMethod": "CASH",
    "printTotalCount": 2,
    "printSuccessCount": 2,
    "status": "SUCCESS",
    "errorMessage": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
    "statusCode": "5555",
    "cashEntered": [{"denomination": "TEN", "total": 40.000}],
    "cashDispensed": [{"denomination": "FIVE", "total": 5.000}, {"denomination": "ONE", "total": 2.000}]
};
