import LocalAdmin from "@/api/LocalAdmin";

export const devices = {
    billDispenser: {
        name: "GLORY_BILL_DISPENSER",
        deviceCode: "01",
        specificStatusMethod: "dispenserStatus",
        bins: {
            codes: ["01", "02"],
            denoms: ["ONES", "FIVES"],
            names: ["BIN1", "BIN2"],
        },
    },
    billAcceptor: {
        name: "MEI_BILL_ACCEPTOR",
        deviceCode: "03",
        specificStatusMethod: null,
    },
    coinDispenser: {
        name: "TELEQUIP_COIN_DISPENSER",
        deviceCode: "04",
        specificStatusMethod: "dispenserStatus",
        bins: {
            codes: ["04", "05", "06"],
            denoms: ["PENNIES", "NICKELS", "QUARTERS"],
            names: ["BIN1", "BIN2", "BIN3"],
        },
    },
};

export const statuses = {
    ok: {
        code: "IXX000",
        description: "is operational.",
        printerResponseCode: "\u0012",
        kioStatusCode: 0,
        statusClass: "info",
        key: "ok",
    },
    empty: {
        code: "EXX001",
        description: "is empty and is not operational.",
        printerResponseCode: "~",
        kioStatusCode: 8,
        statusClass: "error",
        key: "empty",
    },
    low: {
        code: "WXX003",
        description: "is running low and will soon be not operational.",
        printerResponseCode: "\u001E",
        kioStatusCode: 4,
        statusClass: "warning",
        key: "low",
    },
    full: {
        code: "WXX006",
        description: "is full and is not operational.",
        printerResponseCode: "Not applicable",
        kioStatusCode: 4096,
        statusClass: "error",
        key: "full",
    },
    offline: {
        code: "EXX007",
        description: "is not communicating and is not operational.",
        printerResponseCode: "Not applicable",
        kioStatusCode: 1,
        statusClass: "error",
        key: "offline",
    },
    unknown: {
        code: "EXX008",
        description: "has reported an unknown status.",
        printerResponseCode: "Not applicable",
        kioStatusCode: "Not applicable",
        statusClass: "error",
        key: "unknown",
    },
    open: {
        code: "EXX009",
        description: "is open and the device is not operational.",
        printerResponseCode: "Not implemented",
        kioStatusCode: 2,
        statusClass: "error",
        key: "open",
    },
    deviceBusy: {
        code: "EXX011",
        description: "is currently busy and is not operational.",
        printerResponseCode: "Not applicable",
        kioStatusCode: "1024",
        statusClass: "error",
        key: "deviceBusy",
    },
    serialSettingsError: {
        code: "EXX014",
        description:
            "is experiencing a serial setting issue and needs to be configured.",
        printerResponseCode: "Not applicable",
        kioStatusCode: 64,
        statusClass: "error",
        key: "serialSettingsError",
    },
    recoverError: {
        code: "EXX015",
        description:
            "is experiencing a recoverable error and needs to be restarted.",
        printerResponseCode: "Not applicable",
        kioStatusCode: 16,
        statusClass: "error",
        key: "recoverError",
    },
    noRecoverError: {
        code: "EXX016",
        description:
            "is experiencing a non-recoverable error and needs to be serviced.",
        printerResponseCode: "Not applicable",
        kioStatusCode: 32,
        statusClass: "error",
        key: "noRecoverError",
    },
    paperOnSpoolButNotFeeding: {
        code: "EXX017",
        description: "is not feeding and is not operational.",
        printerResponseCode: "r",
        kioStatusCode: "Not applicable",
        statusClass: "error",
        key: "paperOnSpoolButNotFeeding",
    },
    paperJam: {
        code: "EXX017",
        description: "is jammed and is not operational.",
        printerResponseCode: "Not applicable",
        kioStatusCode: "512",
        statusClass: "error",
        key: "paperJam",
    },
};

const pollCashMachineStatuses = () => {
    const statuses = [];
    Object.keys(devices).forEach((key) => {
        const deviceData = {
            device: devices[key],
            status: null,
            deviceType: key,
        }
        pollDeviceStatus(deviceData)
        statuses.push(deviceData);
    });
    return statuses;
};

const pollDeviceStatus = (deviceData) => {
    let deviceStatusResponse;
    let statusResolved = false;
    try {
        deviceStatusResponse = window.KioDevice.GetStatus(deviceData.device.name);
    } catch (e) {
        deviceData.status = statuses.offline;
    }
    if (!isNaN(deviceStatusResponse)) {
        Object.keys(statuses).forEach((key) => {
            if (deviceStatusResponse === statuses[key].kioStatusCode) {
                deviceData.status = statuses[key];
                statusResolved = true;
            }
        });
        if (
            deviceData.device.name === devices.billDispenser.name ||
            deviceData.device.name === devices.coinDispenser.name
        ) {
            if (isBillDispenserBusy(deviceData.device)) {
               deviceData.status = statuses.deviceBusy;
               statusResolved = true;
            }
        }
        if (!statusResolved) {
            deviceData.status = statuses.unknown;
        }
    }

};

function isBillDispenserBusy(device) {
    let binStatusResponse;
    try {
        binStatusResponse = window.KioCurrencyDispenser.GetStatus(device.name);
        binStatusResponse = JSON.parse(binStatusResponse);
    } catch (e) {
        return true;
    }
    if (binStatusResponse.BinCount === 0) {
        return true;
    }
    return false;
}

export default  {
    namespaced: true,
    state: () => ({
        status: true,
        cashAcceptTotal: {},
        cashDispenseTotalResponse: {},
        total: 0,
        cashAcceptorStatus: null,
        cashDispenserStatus: null,
        coinDispenserStatus: null,
        billsRejected: {},
        billsRejectedClearResponse: {},
        showDispenserAccessPage: true,
        loadingDispenserAccessPage: true,
    }),
    mutations: {
        setCashAcceptTotal(state, total){
            state.cashAcceptTotal = total;
        },
        setCashDispenseTotal(state, total){
            state.cashDispenseTotalResponse = total;
        },
        setCashMachineTotal(state, total) {
            state.total = total;
        },
        setCashAcceptorStatus(state, status) {
            state.cashAcceptorStatus = status;
        },
        setCashDispenserStatus(state, status) {
            state.cashDispenserStatus = status;
        },
        setCoinDispenserStatus(state, status) {
            state.coinDispenserStatus = status;
        },
        setBillsRejected(state, payload) {
            state.billsRejected = payload;
        },
        setBillsRejectedResponse(state, payload) {
            state.billsRejectedClearResponse = payload;
        },
    },
    actions: {
        async dispenserAccessPage() {
            let response = await LocalAdmin.dispenseAccessPageInfo();
            return response;
        },
        async getBillsRejected({commit}) {
            const bills = await LocalAdmin.getRejectedBills();
            commit("setBillsRejected", bills.data);
        },
        async resetBillsRejected({commit}) {
            const bills = await LocalAdmin.resetRejectedBills();
            commit("setBillsRejectedResponse", bills.data);
        },
        async getCashDispenseTotal({commit}){
            const total = await LocalAdmin.getCashDispenseTotal();
            commit("setCashDispenseTotal", total.data);
        },
        async getCashMachineTotal({commit}) {
            const info = await LocalAdmin.getCashMachineTotal();
            commit("setCashAcceptTotal", info.data);
        },
        async resetCashMachineTotal({commit}, total) {
            await LocalAdmin.emptyCashMachine({total});
            const info = await LocalAdmin.getCashMachineTotal();
            commit("setCashAcceptTotal", info.data);
        },
        async resetCashDispenser({commit}, resetModel) {
            await LocalAdmin.resetCashDispenser({
                ...resetModel
            });
            const total = await LocalAdmin.getCashDispenseTotal();
            commit("setCashDispenseTotal", total.data);
        },
        async getCashMachineStatuses({commit}) {
            const statuses = pollCashMachineStatuses();
            for (let status of statuses) {
                switch (status.device.name) {
                    case devices.billAcceptor.name:
                        commit("setCashAcceptorStatus", status.status);
                        break;
                    case devices.billDispenser.name:
                        commit("setCashDispenserStatus", status.status);
                        break;
                    case devices.coinDispenser.name:
                        commit("setCoinDispenserStatus", status.status);
                        break;
                }
            }

        },
    },
    getters: {
        cashAcceptTotal(state){
            return state.cashAcceptTotal;
        },
        cashDispenseTotalResponse(state){
            return state.cashDispenseTotalResponse;
        },
        getCashAcceptorStatus(state) {
            return state.cashAcceptorStatus || statuses.offline;
        },
        getDispenserStatus(state) {
            return {
                cashDispenserStatus: state.cashDispenserStatus || statuses.offline,
                coinDispenserStatus: state.coinDispenserStatus || statuses.offline
            };
        }
    },
}

