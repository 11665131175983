<template>
  <div class="flex pt-5 pb-5">
    <div class="flex-1 my-auto">
      <slot name="nav-left">
        <div class="flex justify-start">
          <BackButton @click="goHome"></BackButton>
        </div>
      </slot>
    </div>
    <div class="flex-1 my-auto">
      <slot name="nav-center"></slot>
    </div>
    <div class="flex-1">
      <slot name="nav-right">
        <div class="flex justify-end items-center">
          <img src="@/assets/LogoDarkerText.png" width="200">
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import BackButton from "../Buttons/BackButton";
export default {
  name: "BasicNav",
  components: {BackButton},
  methods: {
    goHome(){
      this.$router.push({name: 'Home'})
    }
  }
}
</script>

<style scoped>

</style>