import LocalAdmin from "@/api/LocalAdmin";
import KioskApi from "@/api/KioskApi";

export default  {
    namespaced: true,
    state: () => ({
        status: {},
        loadingDeviceInfo: false,
        verifoneActivityLogs: [],
        serverParams: {
            sort: 'createDateTime,desc',
            page: 0,
            size: 8,
        },
        verifoneConfiguration: undefined,
    }),
    mutations: {
        setLoadingDeviceInfo(state, value){
            state.loadingDeviceInfo = value;
        },
        setStatus(state, value){
            state.status = value;
        },
        setVerifoneActivityLogs(state, logs) {
            state.verifoneActivityLogs = logs;
        },
        updateParams(state, payload) {
            state.serverParams = {...state.serverParams, ...payload};
        },
        setVerifoneConfiguration(state, payload) {
            state.verifoneConfiguration = payload;
        },
    },
    actions: {
        async getDeviceInfo({commit}) {
            commit("setLoadingDeviceInfo", true);
            try {
                const info = await LocalAdmin.getVerifoneConfiguration();
                commit("setVerifoneConfiguration", info.data);
                // eslint-disable-next-line no-useless-catch
            } catch (e) {
                throw e;
            } finally {
                commit("setLoadingDeviceInfo", false);
            }
        },
        async devicesStatusInfo({commit, rootGetters}){
            commit("setLoadingDeviceInfo", true);
            try {
                const info = (await KioskApi.getVerifoneStatus(rootGetters["GeneralSettings/kioskDetails"].kioskId)).data;
                if (info.responseEnum === "ONLINE") {
                    commit("setStatus", {RESULT: "OK"});
                } else {
                    commit("setStatus", {RESULT: "failed"});
                }
            } catch (e) {
                commit("setStatus", {RESULT: "failed"});
            } finally {
                commit("setLoadingDeviceInfo", false);
            }
        },
        async getVerifoneActivityLogs({state, commit}) {
            const res = await LocalAdmin.getVerifoneActivityLogs(state.serverParams);
            commit("setVerifoneActivityLogs", res.data);
        }
    },
    getters: {
        ccMachineConnectionStatus(state) {
            return !!(state.status && state.status.RESULT && state.status.RESULT === "OK");
        },
        getDeviceInfoLoading(state){
            return state.loadingDeviceInfo;
        },
        verifoneConfiguration(state) {
            return state.verifoneConfiguration;
        },
    },
}

