import axios from "axios";
import remoteInstance from "@/api/RemoteAdmin";

const instance = axios.create({
baseURL: process.env.VUE_APP_API_URL
});

export default  {
    getDevicesStatus() {
        return instance.get("/api/v1/device/register/status");
    },
    getVerifoneConfiguration() {
        return instance.get("/proxy/verifone/configuration");
    },
    getKioskDetails() {
        return instance.get("/kiosk");
    },
    getCashDispenseTotal(){
        return remoteInstance.get(`/cash/dispense/total/`);
    },
    resetCashDispenser(resetModal) {
        return remoteInstance.post("/cash/dispense/reset", resetModal);
    },
    getCashMachineTotal() {
        return remoteInstance.get(`/cash/accept/total/`);
    },
    getPrinterCounter() {
        return remoteInstance.get(`/print/total/`);
    },
    resetPrinterCounter(paperLengthMm) {
        return remoteInstance.post(`/print/reset`, {paperLengthMm});
    },
    emptyCashMachine(emptyModel) {
        return remoteInstance.post("/cash/accept/empty", emptyModel);
    },
    toggleMaintenanceMode(request) {
        return remoteInstance.put("/kiosk", request);
    },
    printRecord(request) {
        return remoteInstance.post("/print", request);
    },
    loginRecord() {
        return remoteInstance.post("/user/login");
    },
    logoutRecord() {
        return remoteInstance.post("/user/logout")
    },
    getPrinterLogs(request){
       return remoteInstance.get("/print/log/list", {params:{...request}});
    },
    createPrinterLog(request){
        return remoteInstance.post("/print/log", request);
    },
    getUserAccessLogs(request) {
        return remoteInstance.get("/user/list", {params: request});
    },
    getUserAccessDetail(request, sessionId) {
        return remoteInstance.get(`user/session/${sessionId}`, {params: request});
    },
    getVerifoneActivityLogs(request) {
        return remoteInstance.get("/verifone/list", {params: request});
    },
    getTransactionLogs(request) {
        return remoteInstance.get("/purchase/list", {params: request});
    },
    getMaintenanceLogs(request) {
        return remoteInstance.get("/maintenance/log/list", {params: request});
    },
    createMaintenanceLog(log) {
        return remoteInstance.post("/maintenance/log", log);
    },
    getAcceptLogs(request) {
        return remoteInstance.get("/cash/accept/list", {params: request});
    },
    getTransactionLogCCDetails(purchaseId) {
        return remoteInstance.get(`/purchase/credit/${purchaseId}`);
    },
    getTransactionLogCashDetails(purchaseId) {
        return remoteInstance.get(`/purchase/cash/${purchaseId}`);
    },
    getAcceptResetDetails(emptyId) {
        return remoteInstance.get(`/cash/accept/empty/${emptyId}`);
    },
    getDispenseLogs(request) {
      return remoteInstance.get("/cash/dispense/list", {params: request});
    },
    getDispenseResetDetails(request) {
        return remoteInstance.get(`/cash/dispense/reset/${request}`);
    },
    getRejectedBills() {
        return remoteInstance.get("/cash/reject/total");
    },
    resetRejectedBills() {
        return remoteInstance.post("/cash/reject/empty");
    },
    dispenseAccessPageInfo() {
        const endpoints = ["/cash/reject/total", "/cash/dispense/total"];
        return  Promise.allSettled(endpoints.map((endpoint) => remoteInstance.get(endpoint)));
    },
    getDispenserCashRejectDetail(id) {
        return remoteInstance.get(`/cash/reject/detail/${id}`);
    },
}
