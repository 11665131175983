<template>
  <BasicCard title="Maintenance Mode" :icon="['far', 'tools']" >
    <template slot="card-actions">
      <div class="flex justify-end content-end items-center h-full">
        <div v-if="loading" class="flex items-center justify-center h-full">
          <font-awesome-icon size="lg" spin icon="spinner" color="orange" />
        </div>
        <div v-else-if="errored" class="flex items-center justify-center h-full">
          <font-awesome-icon size="lg" :icon="['far', 'exclamation-circle']"  color="red" />
        </div>
        <div v-else class="flex items-center">
          <h6 class=" text-lg text-gray-400 mr-2">{{maintenanceModeText}}</h6>
          <ToggleButton :value="inMaintenance" :disabled="disableToggle"  @input="onToggle" />
        </div>
      </div>
    </template>
  </BasicCard>
</template>

<script>
import BasicCard from "../Helpers/Cards/BasicCard";
import ToggleButton from "../Helpers/Inputs/ToggleButton";
export default {
  name: "MaintenanceModeCard",
  props:{
    inMaintenance: {
      type: Boolean,
      required: true,
    },
    disableToggle: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    errored: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    BasicCard, ToggleButton
  },
  computed: {
    maintenanceModeText(){
      return this.inMaintenance ? "On" : "Off"
    }
  },
  methods: {
    onToggle(event) {
      this.$emit("toggle", event);
    }
  },
}
</script>

<style scoped>

</style>