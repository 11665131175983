import axios from "axios";
import {keycloak} from "@/main";

const remoteInstance = axios.create({
    baseURL: process.env.VUE_APP_REMOTE_API_URL
});

remoteInstance.interceptors.request.use(async (config) => {
    const token = keycloak.token;
    config.headers.Authorization = `Bearer ${token}`
    config.headers["TDS-kiosk-serial"] = localStorage.getItem("serialNumber");
    return config;
});

export default remoteInstance;
