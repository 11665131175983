import LocalAdmin from "@/api/LocalAdmin";
import {keycloak} from "../../main";

const mapServerParamsToAccessLogsRequest = (serverParams) => {
    return {
        page: serverParams.page,
        size: serverParams.perPage,
        sort: serverParams.sort
    }
};

export default  {
    namespaced: true,
    state: () => ({
        kioskDetails: {
            loaded: false,
        },
        userAccessLogs: [],
        sessionDetail: [],
        maintenanceLogs: {},
        createMaintenanceLogResponse: {}
    }),
    mutations: {
        setKioskDetails(state, details) {
            state.kioskDetails = details;
        },
        setUserAccessLogs(state, userAccessLogs) {
            state.userAccessLogs = userAccessLogs;
        },
        setUserAccessDetail(state, sessionDetails) {
            state.sessionDetail = sessionDetails;
        },
        setMaintenanceLogs(state, maintenanceLogs) {
            state.maintenanceLogs = maintenanceLogs;
        },
        setCreateMaintenanceLogResponse(state, response) {
            state.createMaintenanceLogResponse = response;
        }
    },
    actions: {
        async getKioskDetails({commit}) {
            const details = await LocalAdmin.getKioskDetails();
            const serialNumber = details.data.serialNumber;
            localStorage.setItem("serialNumber", serialNumber);
            commit("setKioskDetails", {
                ...details.data,
                loaded: true
            });
        },
        async toggleMaintenanceMode(context, toggled) {
            await LocalAdmin.toggleMaintenanceMode({
                inMaintenance: toggled
            });
        },
        async getUserAccessLogs({commit}, request) {
            const accessLogs = await LocalAdmin.getUserAccessLogs(
                mapServerParamsToAccessLogsRequest(request));
            commit("setUserAccessLogs", accessLogs.data);
        },
        async getUserAccessDetail({commit}, {request, sessionId}) {
            const accessDetail = await LocalAdmin.getUserAccessDetail(request,sessionId)
            commit("setUserAccessDetail", accessDetail.data);
        },
        async getMaintenanceLogs({commit}, request) {
            const maintenanceLogs = await LocalAdmin.getMaintenanceLogs(request);
            commit("setMaintenanceLogs", maintenanceLogs.data);
        },
        async createMaintenanceLog({commit}, log) {
            const maintenanceLog = await LocalAdmin.createMaintenanceLog(log);
            commit("setCreateMaintenanceLogResponse", maintenanceLog);
        }
    },
    getters: {
        kioskDetails(state) {
            return state.kioskDetails;
        },
        keycloak () {
            return keycloak;
        },
        keycloakLoggedInUser () {
            return keycloak.idTokenParsed;
        }
    },
}
