<template>
  <button class="p-2 rounded-md text-center flex align-middle" :class="getButtonClass" @click="$emit('click')">
    <font-awesome-icon v-if="loading" spin class="text-white text-center " size="lg" :icon="['fas', 'spinner']"  />
    <font-awesome-icon v-else :class="getIconClass" :icon="this.icon"  />
  </button>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    icon: {
      type: Array,
      default: () =>  ['far', 'arrow-circle-left'],
    },
    iconSize:{
      type: String,
      default: "xl"
    },
    iconColor:{
      type: String,
      default: "white"
    },
    buttonColor:{
      type: String,
      default: "orange"
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    getIconClass(){
      return `text-${this.iconColor} text-${this.iconSize}`
    },
    getButtonClass(){
      return `bg-${this.buttonColor}`
    }
  }
}
</script>

<style scoped></style>