<template>
  <button :disabled="disabled"
          :class="`card ${buttonBackgroundColor} disabled:${disabledColor}  hover:${hoverColor} active:${activeColor}-dark p-3 rounded-md ${buttonTextColor} text-lg font-bold shadow-md`"
          @click="$emit('click')">
    <font-awesome-icon v-if="loading" spin class="text-white text-center " size="lg" :icon="['fas', 'spinner']"  />
    <p v-else class="whitespace-nowrap">{{title}}</p>
  </button>
</template>

<script>
export default {
  name: "BasicButton",
  props: {
    title: {
      type: String,
      required: false,
      default: "Basic Button"
    },
    buttonBackgroundColor:{
      default: 'bg-orange',
      required: false,
    },
    buttonTextColor: {
      default: 'text-white',
      required: false,
    },
    activeColor:{
      type: String,
      required: false,
      default: "bg-orange-dark"
    },
    hoverColor:{
      type: String,
      required: false,
      default: "bg-orange-light"
    },
    disabledColor: {
      type: String,
      required: false,
      default: "bg-gray-300"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>

<style scoped ></style>