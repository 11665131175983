<template>
  <div id="app">
    <TimeoutModal/>
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


</style>
<script>
import TimeoutModal from "@/components/Helpers/Modal/TimeoutModal";
export default {
  components: {TimeoutModal},
  mounted() {
    document.getElementById("app").addEventListener('focusin', e => {
      if(!window.KioApp) return;
      if (["number", "text", "url"].includes(e.target.type)) {
        if(window.KioApp.IsKeyboardShowing()) return;
        window.KioApp.StartKeyboard("", "", e.target.type, false, "", "");
      } else if(window.KioApp.IsKeyboardShowing()) {
        window.KioApp.CloseKeyboard();
      }
    });
  },
}
</script>
