<template>
  <BasicCard title="Printer Settings" :icon="['far', 'print']">
    <template slot="card-actions">
      <div class="flex justify-end">
        <SpinnerIcon v-if="loading"/>
        <DeviceStatus v-else :status="printerDeviceStatus" />
      </div>
    </template>
    <template slot="body">
      <div class="grid grid-cols-2 gap-4">
        <BasicButton class="w-full" title="Print Test" @click="testPrinter" :loading="testPrinterLoading"/>
        <BasicButton class="w-full" title="Printer Counter" @click="resetPrinterCounter" />
        <BasicButton class="w-full" title="Printer Event Log" @click="goToPrinterLog" />
      </div>
    </template>
  </BasicCard>
</template>
<script>
import BasicCard from "../Helpers/Cards/BasicCard";
import BasicButton from "../Helpers/Buttons/BasicButton";
import {mapState, mapActions, mapMutations} from "vuex";
import ToastMixin from "../../mixin/ToastMixin";
import DeviceStatus, {DEVICE_STATUS} from "@/components/Helpers/DeviceStatus";
import SpinnerIcon from "@/components/Helpers/Loading/SpinnerIcon";

export default {
  name: "PrinterSettingsCard",
  components: {
    DeviceStatus,
    BasicCard, BasicButton,
    SpinnerIcon
  },
  props: {
    deviceStatus: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    }
  },
  mixins: [ToastMixin],
  methods: {
    ...mapActions('Printer', ['printerStatus']),
    ...mapActions("ReceiptPrinting", ["printReceipt"]),
    ...mapMutations('Printer', ['setPrinterStatus']),
    ...mapMutations("ReceiptPrinting", ['setTestPrinterLoading', 'setKioskDetails']),
    async refreshPrinter() {
      try {
        await this.printerStatus();
      } catch (e) {
        this.setPrinterStatus(this.DEVICES.printers.tickets.statuses.offline);
        window.printJob = null;
      }
    },
    testPrinter() {
      this.setKioskDetails(this.kioskDetails);
      this.setTestPrinterLoading(true);
      this.printReceipt(this.API_KEYS_PRINT_LOG.TEST);
      this.refreshPrinter();
    },
    resetPrinterCounter() {
      this.$router.push({name: 'ResetPrinterCounter'});
    },
    goToPrinterLog() {
      this.$router.push({name: 'PrinterLogs'});
    }
  },
  computed: {
    ...mapState('GeneralSettings', ['kioskDetails']),
    ...mapState('Printer', ['status', 'DEVICES']),
    ...mapState('ReceiptPrinting', ['testPrinterLoading', 'testPrinterSuccess', 'printError', 'API_KEYS_PRINT_LOG']),
    printerDeviceStatus() {
      const status = this.deviceStatus?.code;
      const statuses = this.DEVICES.printers.tickets.statuses;
      switch (status) {
        case statuses.ok.code:
          return DEVICE_STATUS.ONLINE;
        case statuses.lowPaper.code:
          return DEVICE_STATUS.WARNING;
        case statuses.noPaper.code:
        case statuses.criticallyLowPaper.code:
        case statuses.coverOpen.code:
        case statuses.paperOnSpoolButNotFeeding.code:
        case statuses.unknown.code:
        case statuses.recoverError.code:
        case statuses.noRecoverError.code:
          return DEVICE_STATUS.ERRORED;
        case statuses.offline.code:
        default:
          return DEVICE_STATUS.OFFLINE;
      }
    }
  },
  watch: {
    testPrinterSuccess: function(val){
      if(val){
        this.successToast("Printer Test Successful!");
      }else if(val === false){
        this.errorToast("Printer Test Failed");
      }
    },
    printError() {
      if(this.printError.active === true) {
        this.errorToast(this.printError.message);
      }
    }
  }
}
</script>
