import axios from "axios";

const kioskApi = axios.create({
    baseURL: process.env.VUE_APP_KIOSK_API_URL
});

export default {
    getVerifoneStatus(kioskId) {
      return kioskApi.post("/v1/flix/payment/terminal/response", {
          kioskId
      });
    },
};
