<template>
  <div class="mw-screen mh-screen bg-white pl-10 pr-10 pt-0">
    <slot name="nav"></slot>
    <div class="container mx-auto bg-col bg-gray-200">
      <div class="pt-8 pb-8 shadow-lg-inner w-90/100 m-auto min-h-full">
        <slot name="body">
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageTemplate",
  components: { }
}
</script>

<style scoped>
.body-wrapper{

}
</style>