export default {
    content: [
        {
            "productName": "Booking",
            "status": "Success",
            "reservation": {
                "reservationId": "d84411b2-63af-4878-a464-3795065e0b3f",
                "tripsConfirmation": "12345678",
                "firstName": "Billy",
                "lastName": "Bob",
                "username": "boaty_mc_boat_face@noc.ac.uk",
                "sessionId": "123",
                "createDateTime": "2022-03-16T11:49:50Z"
            },
            "cardType": "MC",
            "amount": "13.37",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
        {
            "productName": "Registered",
            "status": "Success",
            "username": "boaty_mc_boat_face@noc.ac.uk",
            "sessionId": "123",
            "createDateTime": "2022-03-16T11:49:50Z"
        },
    ],
    "pageable": {
        "sort": {"empty": false, "sorted": true, "unsorted": false},
        "offset": 0,
        "pageNumber": 0,
        "pageSize": 8,
        "paged": true,
        "unpaged": false
    },
    "totalElements": 12,
    "totalPages": 2,
    "last": false,
    "size": 8,
    "number": 0,
    "sort": {"empty": false, "sorted": true, "unsorted": false},
    "numberOfElements": 8,
    "first": true,
    "empty": false
}