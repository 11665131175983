<template>
  <div class="flex items-center justify-center h-full">
    <font-awesome-icon size="lg" spin icon="spinner" color="orange" />
  </div>
</template>

<script>
export default {
  name: "SpinnerIcon"
}
</script>