export default {
    errorCode: "9999",
    "originalValues": [
        {
            "binId": "1",
            "denominationType": "CASH",
            "denomination": "FIVE",
            "quantity": 82,
            "total": 410,
            "lastResetDateTime": "2022-03-17T14:27:25.632Z",
            "lastDispenseDateTime": null
        },
        {
            "binId": "2",
            "denominationType": "CASH",
            "denomination": "ONE",
            "quantity": 50,
            "total": 50,
            "lastResetDateTime": "2022-03-17T14:27:25.632Z",
            "lastDispenseDateTime": null
        },
        {
            "binId": "3",
            "denominationType": "COIN",
            "denomination": "QUARTER",
            "quantity": 19,
            "total": 4.75,
            "lastResetDateTime": "2022-03-17T14:27:25.632Z",
            "lastDispenseDateTime": null
        },
        {
            "binId": "4",
            "denominationType": "COIN",
            "denomination": "NICKEL",
            "quantity": 12,
            "total": 0.6,
            "lastResetDateTime": "2022-03-17T14:27:25.632Z",
            "lastDispenseDateTime": null
        },
        {
            "binId": "5",
            "denominationType": "COIN",
            "denomination": "PENNY",
            "quantity": 154,
            "total": 1.54,
            "lastResetDateTime": "2022-03-17T14:27:25.632Z",
            "lastDispenseDateTime": null
        }
    ],
    "newValues": [
        {
            "binId": "1",
            "denominationType": "CASH",
            "denomination": "FIVE",
            "quantity": 82,
            "total": 410,
            "lastResetDateTime": null,
            "lastDispenseDateTime": null
        },
        {
            "binId": "2",
            "denominationType": "CASH",
            "denomination": "ONE",
            "quantity": 53,
            "total": 53,
            "lastResetDateTime": null,
            "lastDispenseDateTime": null
        },
        {
            "binId": "3",
            "denominationType": "COIN",
            "denomination": "QUARTER",
            "quantity": 19,
            "total": 4.75,
            "lastResetDateTime": null,
            "lastDispenseDateTime": null
        },
        {
            "binId": "4",
            "denominationType": "COIN",
            "denomination": "NICKEL",
            "quantity": 12,
            "total": 0.6,
            "lastResetDateTime": null,
            "lastDispenseDateTime": null
        },
        {
            "binId": "5",
            "denominationType": "COIN",
            "denomination": "PENNY",
            "quantity": 154,
            "total": 1.54,
            "lastResetDateTime": null,
            "lastDispenseDateTime": null
        }
    ]
}