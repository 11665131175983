<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-left">
          <div class="flex justify-start">
            <back-button @click="logout"></back-button>
          </div>
        </template>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">Admin Panel</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div class="flex mb-8 space-x-6">
        <MaintenanceModeCard
            class="flex-1"
            :loading="loadingKioskDetails"
            :disable-toggle="togglingMaintenanceMode"
            :in-maintenance="inMaintenance"
            :errored="kioskDetailsErrored"
            @toggle="onToggleMaintenanceMode"
        />
        <RefreshCard @refresh="fetchAllDeviceStatuses"/>
      </div>
      <div class="grid grid-cols-2 gap-8">
        <GeneralSettingsCard />
        <PrinterSettingsCard
            :loading="printerStatusLoading"
            :device-status="getPrinterStatus"
        />
        <CashMachineSettingsCard
            v-if="!cardOnly"
            :loading="loadingCashSystemStatus"
            :acceptor-status="getCashAcceptorStatus"
            :dispenser-status="getDispenserStatus"
        />
        <CreditCardMachineSettingsCard
            class="self-start"
            :device-connected="ccMachineConnectionStatus"
            :loading="getDeviceInfoLoading"
        />
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import BasicNav from "../components/Helpers/Navs/BasicNav";
import PageTemplate from "../components/Helpers/Pages/PageTemplate";
import GeneralSettingsCard from "../components/Cards/GeneralSettingsCard";
import PrinterSettingsCard from "../components/Cards/PrinterSettingsCard";
import CreditCardMachineSettingsCard from "../components/Cards/CreditCardMachineSettingsCard";
import CashMachineSettingsCard from "../components/Cards/CashMachineSettingsCard";
import MaintenanceModeCard from "../components/Cards/MaintenanceModeCard";
import ToastMixin from "../mixin/ToastMixin";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import BackButton from "@/components/Helpers/Buttons/BackButton";
import {keycloak} from "@/main";
import RefreshCard from "@/components/Helpers/Cards/RefreshCard";
import LocalAdmin from "@/api/LocalAdmin";

export default {
  name: "MainMenu",
  data(){
    return {
      loadingKioskDetails: true,
      kioskDetailsErrored: false,
      togglingMaintenanceMode: false,
      loadingCashSystemStatus: true,
    }
  },
  mixins: [ToastMixin],
  components: {
    RefreshCard,
    CashMachineSettingsCard,
    CreditCardMachineSettingsCard,
    PrinterSettingsCard,
    GeneralSettingsCard,
    MaintenanceModeCard,
    PageTemplate, BasicNav,
    BackButton
  },
    mounted() {
     this.fetchAllDeviceStatuses();
  },
  methods: {
    ...mapActions("CreditCardMachine", ["devicesStatusInfo", "getDeviceInfo"]),
    ...mapActions("GeneralSettings", ["getKioskDetails", "toggleMaintenanceMode"]),
    ...mapActions("CashMachine", ["getCashMachineStatuses"]),
    ...mapActions('Printer', ['printerStatus']),
    ...mapMutations('Printer', ['setPrinterStatus']),
    async fetchAllDeviceStatuses() {
      await this.fetchKioskDetails();
      await this.fetchCCDeviceStatus();
      await this.fetchPrinterStatus();
      await this.fetchCashSystemStatus();
    },
    async fetchKioskDetails(){
      try {
        this.loadingKioskDetails = true;
        await this.getKioskDetails();
        this.loadingKioskDetails = false;
        this.kioskDetailsErrored = false;
      } catch (e) {
        this.errorToast("Failed to fetch kiosk details")
        this.kioskDetailsErrored = true;
      } finally {
        this.loadingKioskDetails = false;
      }
    },
    async fetchPrinterStatus() {
      try {
        await this.printerStatus();
      } catch (e) {
        this.setPrinterStatus(this.DEVICES.printers.tickets.statuses.offline)
        this.errorToast("Failed to fetch printer status");
        window.printJob = null;
      }
    },
    async fetchCCDeviceStatus() {
      try {
        await this.getDeviceInfo();
        await this.devicesStatusInfo();
      } catch (e) {
        this.errorToast("Failed to fetch credit card device status");
      }
    },
    async fetchCashSystemStatus() {
      this.loadingCashSystemStatus = true;
      try {
        await this.getCashMachineStatuses();
      } catch (e) {
        this.errorToast("Failed to fetch catch system status");
      } finally {
        this.loadingCashSystemStatus = false;
      }
    },
    async onToggleMaintenanceMode(toggled) {
      try {
        this.togglingMaintenanceMode = true;
        await this.toggleMaintenanceMode(toggled);
        await this.getKioskDetails();
        this.successToast(`Maintenance mode turned ${this.inMaintenance ? 'on' : 'off'}`);
      } catch (e) {
        this.errorToast(`Unable to toggle maintenance mode`);
      } finally {
        this.togglingMaintenanceMode = false;
      }
    },
    logout() {
      LocalAdmin.logoutRecord();
      if (window.KioApp) {
        // if app is running in kioware, then restart kioware which will land the user on the booking ui homepage
        window.KioApp.RestartKioWare(false);
      } else {
        // if a developer is not running the app in kioware, then just logout of keycloak so the page doesn't explode
        keycloak.logout();
      }
    }
  },
  computed: {
    ...mapState("GeneralSettings", ["kioskDetails"]),
    ...mapState("Printer", ["printerStatusLoading"]),
    ...mapGetters("CreditCardMachine", ["ccMachineConnectionStatus", "getDeviceInfoLoading"]),
    ...mapGetters('Printer',['getPrinterStatus']),
    ...mapGetters("CashMachine", ["getCashAcceptorStatus", "getDispenserStatus"]),
    inMaintenance() {
      return this.kioskDetails?.inMaintenance || false;
    },
    cardOnly() {
      return this.kioskDetails.cardOnly;
    }
  },
}
</script>
