import {setupServer} from "msw/node";
import {rest, setupWorker} from "msw";
import adminAccessLogsResponse from "./mockResponses/adminAccessLogsResponse";
import verifoneListResponse from "@/mockResponses/verifoneListResponse";
import acceptorListResponse from "./mockResponses/acceptorListResponse";
import purchaseListResponse from "@/mockResponses/purchaseListResponse";
import acceptorResetDetailResponse from "./mockResponses/acceptorResetDetailResponse";
import acceptorPurchaseDetailResponse from "./mockResponses/acceptorPurchaseDetailResponse";
import printerListResponse from "@/mockResponses/printListResponse";
import dispenserResetDetailResponse from "./mockResponses/dispenserResetDetailResponse";
import creditCardPurchaseDetailResponse from "./mockResponses/creditCardPurchaseDetailResponse";
import rejectedBillsResponse from "./mockResponses/rejectedBillsResponse";
import dispenseTotalResponse from "./mockResponses/dispenseTotalResponse";
import dispenserRejectResetDetail from "./mockResponses/dispenserRejectResetDetail";

const createHandlers = (baseUrl) =>
 [
    rest.get(`${baseUrl}/user/list`, (req, res, ctx) => {
    return res(
        ctx.json(adminAccessLogsResponse)
    )
    }),
    rest.post(`${baseUrl}/user/logout`, (req, res, ctx) => {
    return res(
        ctx.json({
            status: "ok"
        })
    );
    }),
    rest.get(`${baseUrl}/verifone/list`, (req, res, ctx) => {
    const size = parseInt(req.url.searchParams.get("size"));
    const page = parseInt(req.url.searchParams.get("page"));
    const totalPages = Math.ceil(verifoneListResponse.content.length / size);
    const offset = size * page;
    return res(
        ctx.json({
            ...verifoneListResponse,
            content: verifoneListResponse.content.slice(offset, offset + 8),
            pageable: {
                sort: verifoneListResponse.pageable.sort,
                offset,
                pageNumber: page,
                pageSize: size,
                paged: true,
                unpaged: false
            },
            totalElements: verifoneListResponse.content.length,
            totalPages,
            last: page === totalPages - 1,
            size,
            number: page,
            sort: verifoneListResponse.sort,
            numberOfElements: size,
            first: page === 0,
            empty: false
        })
    );
    }),
    rest.get(`${baseUrl}/cash/accept/list`, (req, res, ctx) => {
     const page = parseInt(req.url.searchParams.get("page"));
     return res(
         ctx.json({...acceptorListResponse[page]})
     );
    }),
    rest.get(`${baseUrl}/purchase/cash/:id`, (req, res, ctx) => {
     const { id } = req.params;
     switch(id) {
         case "fail": return res(
             ctx.status(401),
             ctx.json({"code":999999,"message":"Encountered unexpected error"})
         );
         case "partial-print": {
             return res(
                 ctx.json({...acceptorPurchaseDetailResponse, printTotalCount: 2, printSuccessCount: 1})
             );
         }
         case "failed-print": return res(
             ctx.json({...acceptorPurchaseDetailResponse, printTotalCount: 2, printSuccessCount: 0})
         );
         default: return res(ctx.json({...acceptorPurchaseDetailResponse}));
     }
    }),
    rest.get(`${baseUrl}/cash/accept/empty/:id`, (req, res, ctx) => {
     const { id } = req.params
     if (id === "totallyLegit") {
         return res(
             ctx.json({...acceptorResetDetailResponse})
         );
     } else {
         return res(
             ctx.status(401),
             ctx.json({"code":999999,"message":"Encountered unexpected error"})
         );
     }
    }),
    rest.get(`${baseUrl}/purchase/list`, (req, res, ctx) => {
     const page = parseInt(req.url.searchParams.get("page"));
     return res(
         ctx.json({...purchaseListResponse[page]})
     );
    }),
    rest.get(`${baseUrl}/purchase/credit/:id`, (req, res, ctx) => {
     const { id } = req.params;
     if(id === "fail"){
         return res(
             ctx.status(401),
             ctx.json({"code":999999,"message":"Encountered unexpected error"})
         );
     }else{
         return res(
             ctx.json({...creditCardPurchaseDetailResponse})
         );
     }
    }),
    rest.get(`${baseUrl}/cash/dispense/reset/:id`, (req, res, ctx) => {
     const { id } = req.params
     if (id === "fail") {
         return res(
             ctx.status(401),
             ctx.json({"errorCode":999999,"message":"Encountered unexpected error"})
         );
     } else {
         return res(
             ctx.json({...dispenserResetDetailResponse})
         );
     }
    }),
    rest.get(`${baseUrl}/print/log/list`, (req, res, ctx) => {
     const page = parseInt(req.url.searchParams.get("page"));
     console.log(printerListResponse);
     return res(
         ctx.json({...printerListResponse[page]})
     );
    }),
    rest.get(`${baseUrl}/cash/dispense/total`, (req, res, ctx) => {
        return res(
            ctx.json({...dispenseTotalResponse})
        );
    }),
     rest.get(`${baseUrl}/cash/reject/total`, (req, res, ctx) => {
         return res(
             ctx.json({...rejectedBillsResponse})
         );
     }),
    rest.post(`${baseUrl}/cash/reject/reset`, (req, res, ctx) => {
     return res(
         ctx.json({...rejectedBillsResponse})
     );
    }),
     rest.get(`${baseUrl}/cash/reject/detail/:id`, (req, res, ctx) => {
         const { id } = req.params
         if (id === "fail") {
             return res(
                 ctx.status(401),
                 ctx.json({"errorCode":999999,"message":"Encountered unexpected error"})
             );
         } else {
             return res(
                 ctx.json({...dispenserRejectResetDetail})
             );
         }
     }),
];

export const createTestServer = (baseUrl) => {
    return setupServer(...createHandlers(baseUrl));
};

export const createDevServer = (baseUrl) => {
    return setupWorker(...createHandlers(baseUrl));
};
