<template>
  <BasicCard class="flex items-center justify-center px-5">
    <template #default>
      <IconButton  :icon="['far', 'sync']" @click="refresh"/>
    </template>
  </BasicCard>
</template>

<script>
import BasicCard from "@/components/Helpers/Cards/BasicCard";
import IconButton from "@/components/Helpers/Buttons/IconButton";

export default {
  name: "RefreshCard",
  components: {
    BasicCard,
    IconButton
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    }
  }
}
</script>
