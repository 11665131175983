<template>
  <div class="bg-white rounded-lg p-3 shadow-lg">
    <slot>
      <div class="card-header flex justify-between" :class="{ 'p-3': title }">
        <div class="card-title flex justify-start items-center">
          <font-awesome-icon v-if="icon" class="text-orange text-center ml-1 mr-2 text-2xl" :icon="icon"  />
          <h1 class="text-xl ml-1 align-middle">{{title}}</h1>
        </div>
        <div class="card-actions">
          <slot name="card-actions"></slot>
        </div>
      </div>
      <div class="bard-body" :class="{ 'border-t p-3': title }" v-if="hasBodySlot">
        <slot name="body"></slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "BasicCard",
  props: {
    title: {
      type: String,
      default: "",
      required: false
    },
    icon: {
      type: Array,
     required: false
    }
  },
  computed:{
    hasBodySlot() {
      return !!this.$slots.body
    }
  }
}
</script>

<style scoped>

</style>