<template>
  <BasicCard title="General Settings" :icon="['far', 'cogs']" >
    <template slot="card-actions">
    </template>
    <template slot="body">
      <div class="grid grid-cols-2 gap-4">
        <BasicButton class="w-full " title="Kiosk Details" @click="kioskDetails" />
        <BasicButton class="w-full " title="Maintenance Event Log" @click="toMaintenanceEventLog" />
        <BasicButton class="w-full " title="Access Event Log" @click="toAdminAccessEventLog" />
        <BasicButton class="w-full " title="Purchase Event Log" @click="toPurchaseEventLog" />
      </div>
    </template>
  </BasicCard>
</template>

<script>
import BasicButton from "../Helpers/Buttons/BasicButton";
import BasicCard from "../Helpers/Cards/BasicCard";
export default {
  name: "GeneralSettingsCard",
  components: {
    BasicButton, BasicCard
  },
  methods:{
    kioskDetails(){
      this.$router.push({name: "KioskDetails"});
    },
    toMaintenanceEventLog() {
      this.$router.push({name: "MaintenanceLogs"});
    },
    toAdminAccessEventLog() {
      this.$router.push({name: "AdminAccessLogs"});
    },
    toPurchaseEventLog() {
      this.$router.push({name: "TransactionLogs"});
    }
  }
}
</script>

<style scoped>

</style>
