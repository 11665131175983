<template>
  <div>
    <span
        class="toggle-wrapper"
        role="checkbox"
        :aria-checked="value.toString()"
        tabindex="0"
        @click="toggle"
        @keydown.space.prevent="toggle"
    >
      <span
          class="toggle-background"
          :class="backgroundStyles"
      />
      <span
          class="toggle-indicator"
          :style="indicatorStyles"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    backgroundStyles() {
      return {
        'bg-orange': this.value,
        'bg-gray': !this.value
      };
    },
    indicatorStyles() {
      return { transform: this.value ? 'translateX(28px)' : 'translateX(0)' };
    },

  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit('input', !this.value);
      }
    }
  }
};</script>

<style scoped>


.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 18px;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color .4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 28px;
  width: 28px;
  left: -3px;
  bottom: -4px;
  background-color: #E4E4E4;
  border-radius: 9999px;
  box-shadow:  0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform .4s ease;
}
</style>