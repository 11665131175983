import Vue from 'vue';
import Vuex from 'vuex';
import Printer from "./modules/Printer";
import CashMachine from "./modules/CashMachine";
import CreditCardMachine from "./modules/CreditCardMachine";
import GeneralSettings from "./modules/GeneralSettings";
import ReceiptPrinting from "./modules/ReceiptPrinting";
import TransactionLogs from "./modules/TransactionLogs";
import AcceptLogs from "./modules/AcceptLogs";
import DispenseLogs from "./modules/DispenseLogs"
import AcceptDetail from "./modules/AcceptDetail";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
      Printer,
      GeneralSettings,
      CashMachine,
      CreditCardMachine,
      ReceiptPrinting,
      TransactionLogs,
      AcceptLogs,
      DispenseLogs,
      AcceptDetail,
  }
})
export default store;
