import { render, staticRenderFns } from "./BasicButton.vue?vue&type=template&id=2256c334&scoped=true&"
import script from "./BasicButton.vue?vue&type=script&lang=js&"
export * from "./BasicButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2256c334",
  null
  
)

export default component.exports