<template>
  <button @click="click">
    <font-awesome-icon class="text-orange text-5xl" :icon="['far', 'arrow-circle-left']"   />
  </button>
</template>

<script>
export default {
  name: "BackButton",
  methods:{
    click(){
      this.$emit('click')
    }
  }
}
</script>

<style scoped></style>