<template>
  <BasicCard title="Cash/Coin Machine Settings" :icon="['far', 'money-bill']">
    <template slot="card-actions">
      <div class="h-full flex items-center">
        <SpinnerIcon v-if="loading"/>
        <div v-else class="flex items-center justify-between w-full">
          <div class="flex items-center mr-3">
            <DeviceStatus :small="true" :status="dispenserDeviceStatus" />
            <span class="ml-1 text-sm">Dispenser</span>
          </div>
          <div class="flex items-center">
            <DeviceStatus :small="true" :status="acceptorDeviceStatus" />
            <span class="ml-1 text-sm">Acceptor</span>
          </div>
        </div>
      </div>
    </template>
    <template slot="body">
      <div class="grid grid-cols-2 gap-4">
        <BasicButton class="w-full" title="Dispenser Access" @click="toDispenserPage" />
        <BasicButton class="w-full" title="Acceptor Access" @click="toAcceptor" />
        <BasicButton class="w-full" title="Dispense Event Log" @click="toDispenseEventLog" />
        <BasicButton class="w-full" title="Acceptor Event Log" @click="toAcceptorEventLog" />
      </div>
    </template>
  </BasicCard>
</template>

<script>

import BasicCard from "../Helpers/Cards/BasicCard";
import BasicButton from "../Helpers/Buttons/BasicButton";
import DeviceStatus, { DEVICE_STATUS } from "@/components/Helpers/DeviceStatus";
import SpinnerIcon from "@/components/Helpers/Loading/SpinnerIcon";
import {statuses} from "@/store/modules/CashMachine";

export default {
  name: "CashMachineSettingsCard",
  components: {
    SpinnerIcon,
    DeviceStatus,
    BasicCard, BasicButton
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    dispenserStatus: {
      type: Object,
      required: true,
    },
    acceptorStatus: {
      type: Object,
      required: true,
    }
  },
  methods: {
    toAcceptor() {
      this.$router.push({ name: "AcceptorStatus" });
    },
    toDispenserPage(){
      this.$router.push({ name: "DispenserAccess" });
    },
    toDispenseEventLog() {
      this.$router.push({ name: "DispenserLogs" });
    },
    toAcceptorEventLog() {
      this.$router.push({ name: "AcceptorLogs" })
    },
    getDeviceStatusFromStatusClass(status) {
      switch (status.statusClass) {
        case "info":
          return DEVICE_STATUS.ONLINE;
        case "error":
          if (status.code === statuses.offline.code) {
            return DEVICE_STATUS.OFFLINE;
          }
          return DEVICE_STATUS.ERRORED;
        case "warning":
          return DEVICE_STATUS.WARNING;
      }
    }
  },
  computed: {
    dispenserDeviceStatus() {
      const cashDispenserStatus = this
          .getDeviceStatusFromStatusClass(this.dispenserStatus.cashDispenserStatus);
      const coinDispenserStatus = this
          .getDeviceStatusFromStatusClass(this.dispenserStatus.coinDispenserStatus);
      if (cashDispenserStatus === DEVICE_STATUS.ONLINE && coinDispenserStatus === DEVICE_STATUS.ONLINE) {
        return DEVICE_STATUS.ONLINE;
      } else if (cashDispenserStatus === DEVICE_STATUS.OFFLINE || coinDispenserStatus === DEVICE_STATUS.OFFLINE) {
        return DEVICE_STATUS.OFFLINE;
      } else if (cashDispenserStatus === DEVICE_STATUS.ERRORED || coinDispenserStatus === DEVICE_STATUS.ERRORED) {
        return DEVICE_STATUS.ERRORED;
      } else {
        return DEVICE_STATUS.WARNING;
      }
    },
    acceptorDeviceStatus() {
      return this.getDeviceStatusFromStatusClass(this.acceptorStatus);
    }
  },
}
</script>

<style scoped>

</style>
