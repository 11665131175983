import LocalAdmin from "@/api/LocalAdmin";

export default  {
    namespaced: true,
    state: () => ({
        data: { cashDispensed: []},
    }),
    mutations: {
        data(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async fetchTransaction({commit}, id) {
            const details = await LocalAdmin.getTransactionLogCashDetails(id);
            commit("data" , details.data);
        },
        async fetchEmpty({commit}, id) {
            const details = await LocalAdmin.getAcceptResetDetails(id);
            commit("data" , details.data);
        },
    },
    getters: {
        data(state) {
            return {
                ...state.data,
                cashDispensed: [],
                createDateTime: null,
                event: null,
                status: null,
                printed: null,
                paymentMethod: null,
            }
        },
    },
}
