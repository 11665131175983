import LocalAdmin from "@/api/LocalAdmin";

export default  {
    namespaced: true,
    state: () => ({
        responseData: {
            content: [],
            totalElements: 0
        },
        serverParams: {
            sort: 'createDateTime,desc',
            page: 0,
            size: 10,
        },
    }),
    mutations: {
        responseData(state, payload) {
            state.responseData = payload;
        },
        updateParams(state, payload) {
            state.serverParams = {...state.serverParams, ...payload};
        },
    },
    actions: {
        async fetchLogs({state, commit}) {
            const resp = await LocalAdmin.getAcceptLogs(state.serverParams);
            commit("responseData", resp.data);
        },
    },
    getters: {
        logs(state) {
            return state.responseData.content.map((obj) => {
                return {
                    event: obj.event,
                    status: obj.status,
                    user: obj.username,
                    dateTime: obj.createDateTime,
                    details: {
                        id: obj.recordId,
                        reset: obj.event === "RESET",
                    },
                };
            });
        },
        currentPage(state) {
            return state.serverParams.page;
        },
        totalRows(state) {
            return state.responseData.totalElements;
        },
    },
}
