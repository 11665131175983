export default {
    "cardType": "MASTERCARD",
    "confirmationNumber": "42727383",
    "createDateTime": "2022-03-25T18:31:17.316Z",
    "errorMessage": "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Aldus PageMaker including versions of Lorem Ipsum",
    "event": "BOOKING",
    "lastFour": "4111",
    "printTotalCount": 2,
    "printSuccessCount": 2,
    "status": "SUCCESS",
    "statusCode": "9999",
    "transactionAmount": 75.17,
};