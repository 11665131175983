import { DateTimeFormatter, ZonedDateTime, ZoneId } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
require("@js-joda/timezone");

const DEFAULT_MASK = "M/d/yy HH:mm:ss a";

export const formatZonedDateTime = (date, mask = DEFAULT_MASK) => {
    return DateTimeFormatter.ofPattern(mask)
        .withLocale(Locale.ENGLISH)
        .format(ZonedDateTime.parse(date));
};

export const kioskZonedDateTime = (date, timeZone) => {
    const formatter = DateTimeFormatter.ofPattern(DEFAULT_MASK).withLocale(Locale.ENGLISH);
    return ZonedDateTime.parse(date)
        .withZoneSameInstant(ZoneId.of(timeZone))
        .format(formatter);
};
