<template>
  <BasicModal v-if="visible">
    <template slot="body">
      <div class="timeout-modal flex flex-col items-center">
        <div class="icon-wrapper border-orange">
          <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="7x" :icon="['far', 'hourglass']" />
        </div>
        <h2 class="text-orange text-3xl mt-8 mb-10 uppercase">Are you finished?</h2>
        <div class="flex justify-between w-full">
          <BasicButton @click="onCancel" class="w-5/12" title="NO" button-background-color="bg-gray-500" />
          <BasicButton @click="onDone" class="w-5/12" title="YES, I'M DONE" />
        </div>
      </div>
    </template>
  </BasicModal>
</template>

<script>
import BasicModal from "@/components/Helpers/Modal/BasicModal";
import BasicButton from "@/components/Helpers/Buttons/BasicButton";
import {keycloak} from "@/main";
import {mapState} from "vuex";
import LocalAdmin from "@/api/LocalAdmin";

export default {
  name: "TimeoutModal",
  components: {
    BasicModal,
    BasicButton
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    this.startTimeout();
    document.addEventListener("click", () => {
      if (!this.visible) {
        this.resetTimeout();
      }
    });
    document.addEventListener("input", () => {
      if (!this.visible) {
        this.resetTimeout();
      }
    });
    document.addEventListener("touchstart", (e) => {
      if (!this.visible) {
        this.resetTimeout();
        e.preventDefault()
      }
    });
  },
  beforeDestroy() {
    clearTimeout(this.timeoutInterval);
  },
  methods: {
    startTimeout() {
      this.timeoutInterval = setTimeout(() => {
        this.visible = true;
        this.startLogoutTimeout();
      }, Number.parseInt(process.env.VUE_APP_SESSION_TIMEOUT));
    },
    resetTimeout() {
      clearTimeout(this.timeoutInterval);
      this.visible = false;
      this.startTimeout();
    },
    startLogoutTimeout() {
      this.logoutTimeout = setTimeout(() => {
        this.logout();
      }, Number.parseInt(process.env.VUE_APP_LOGOUT_TIMEOUT));
    },
    logout() {
      LocalAdmin.logoutRecord();
      if (window.KioApp) {
        // if app is running in kioware, then restart kioware which will land the user on the booking ui homepage
        window.KioApp.RestartKioWare(false);
      } else {
        // if a developer is not running the app in kioware, then just logout of keycloak so the page doesn't explode
        keycloak.logout();
      }
    },
    onCancel() {
      this.resetTimeout();
      clearTimeout(this.logoutTimeout);
    },
    onDone() {
      this.logout();
    }
  },
  computed: {
    ...mapState("GeneralSettings", ["kioskDetails"]),
  }
}
</script>

<style scoped>
.icon-wrapper {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 12px;
}

.timeout-modal {
  width: 400px;
}
</style>
