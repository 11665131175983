import LocalAdmin from "@/api/LocalAdmin";
export default   {
        namespaced: true,
        state: () => ({
            printerStatusLoading: false,
            status: {},
            total: {},
            DEVICES : {
                printers: {
                    tickets: {
                        // device status code is what the printer will respond with when asked for status
                        name: "CUSTOM_PRINTER",
                        statuses: {
                            ok: {
                                code: "I04000",
                                description: "is ready to print.",
                                deviceStatusCode: "\u0012",
                                statusClass: "info",
                                quickStatusDisplay: "Connected",
                                quickStatusIcon: "",
                                quickStatusClass: "green-500"
                            },
                            lowPaper: {
                                code: "W04003",
                                description:
                                    "is nearly out of paper and will soon be unable to print.",
                                deviceStatusCode: "\u001E",
                                byteIndex: 2,
                                printerResponseValue: [4],
                                statusClass: "warning",
                                quickStatusDisplay: "Warning",
                                quickStatusIcon: ["far", "times-circle"],
                                quickStatusClass: "red-500"
                            },
                            criticallyLowPaper: {
                                code: "xyz",
                                description:
                                    "is too close to running out of paper and cannot print anymore.",
                                deviceStatusCode: "Not applicable",
                                statusClass: "error",
                                quickStatusDisplay: "Error",
                                quickStatusIcon: "",
                                quickStatusClass: "yellow-500"
                            },
                            offline: {
                                code: "E04007",
                                description:
                                    "is not communicating with the kiosk and is not operational.",
                                deviceStatusCode: "Not applicable",
                                statusClass: "error",
                                quickStatusDisplay: "Disconnected",
                                quickStatusIcon: "",
                                quickStatusClass: "gray-500"
                            },
                            noPaper: {
                                code: "E04001",
                                description: "is out of paper and cannot print anymore.",
                                deviceStatusCode: "~",
                                byteIndex: 2,
                                printerResponseValue: [1],
                                statusClass: "error",
                                quickStatusDisplay: "Error",
                                quickStatusIcon: ["far", "exclamation-triangle"],
                                quickStatusClass: "yellow-500"
                            },
                            coverOpen: {
                                code: "xyz",
                                description:
                                    "'s container is open and the device is not operational.",
                                deviceStatusCode: "Not implemented",
                                byteIndex: 3,
                                printerResponseValue: [1, 2],
                                statusClass: "error",
                                quickStatusDisplay: "Error",
                                quickStatusIcon: ["far", "exclamation-triangle"],
                                quickStatusClass: "yellow-500"
                            },
                            paperOnSpoolButNotFeeding: {
                                code: "E04001",
                                description:
                                    "is detecting paper, but the paper is not feeding. This device is not operational",
                                deviceStatusCode: "r",
                                byteIndex: 3,
                                printerResponseValue: [4],
                                statusClass: "error",
                                quickStatusDisplay: "Error",
                                quickStatusIcon: ["far", "exclamation-triangle"],
                                quickStatusClass: "yellow-500"
                            },
                            unknown: {
                                code: "E04008",
                                description: "has reported an unknown status.",
                                deviceStatusCode: "Not applicable",
                                statusClass: "error",
                                byteIndex: "not applicable",
                                printerResponseValue: "not applicable",
                                quickStatusDisplay: "Error",
                                quickStatusIcon: ["far", "exclamation-triangle"],
                                quickStatusClass: "yellow-500"
                            },
                            recoverError: {
                                code: "xyz",
                                description:
                                    "is experiencing a recoverable error and needs to be restarted.",
                                deviceStatusCode: "Not applicable",
                                byteIndex: 4,
                                printerResponseValue: [1, 2, 8, 32],
                                statusClass: "error",
                                quickStatusDisplay: "Error",
                                quickStatusIcon: ["far", "exclamation-triangle"],
                                quickStatusClass: "yellow-500"
                            },
                            paperJam: {
                                code: "xyz",
                                description:
                                    "is jammed and is not operational.",
                                deviceStatusCode: "Not applicable",
                                statusClass: "error",
                                byteIndex: 4,
                                printerResponseValue: [64],
                                quickStatusDisplay: "Error",
                                quickStatusIcon: ["far", "exclamation-triangle"],
                                quickStatusClass: "yellow-500"
                            },
                            noRecoverError: {
                                code: "xyz",
                                description:
                                    "is experiencing a non-recoverable error and needs to be serviced.",
                                deviceStatusCode: "Not applicable",
                                byteIndex: 5,
                                printerResponseValue: [1, 2, 4, 12, 128],
                                statusClass: "error",
                                quickStatusDisplay: "Error",
                                quickStatusIcon: ["far", "exclamation-triangle"],
                                quickStatusClass: "yellow-500"
                            },
                        },
                    },
                },
            },
            PAPER_LENGTH_Mm: 196596,
            testPrinterSuccess: false,
            testPrinterLoading: false,
            resetPrinterCounterResponse:{},
            printerLogs: {},
        }),
        mutations: {
            setPrinterStatus(state,payload) {
                state.status = payload
            },
            setPrinterTotal(state,payload){
                state.total = payload.data
            },
            setPrinterResetResponse(state, payload){
                state.resetPrinterCounterResponse = payload;
            },
            setPrinterStatusLoading(state, payload) {
                state.printerStatusLoading = payload;
            },
            setPrinterLogs(state, payload){
                state.printerLogs = payload;
            },
        },
        actions: {
            testPrintFailure({commit}) {
                if (window.printJob) {
                    window.printJob = null;
                    commit('ReceiptPrinting/setTestPrinterSuccess', false, {root: true});
                    commit('ReceiptPrinting/setTestPrinterLoading', false, {root: true});
                }
            },
            testPrintAllow() {
                if (window.printJob) {
                    window.printJob();
                }
            },
            async printerStatus({state, commit, dispatch}) {
                window.OnKioDeviceCmdResult = (devName, id, cmd, err, data) => {
                    // 'data' is the serial response from the device
                    const statuses = state.DEVICES.printers.tickets.statuses;
                    let statusResponse;
                    commit("setPrinterStatusLoading", false);
                    try {
                        statusResponse = data.DataList[0].Data;
                    } catch (e) {
                        //this catches the error caused by an unresponsive printer
                        commit('setPrinterStatus', statuses.offline);
                        window.OnKioDeviceCmdResult = null;
                        dispatch("testPrintFailure");
                        return;
                    }
                    const dataArray = [];
                    const buffer = Buffer.from(statusResponse);
                    let printerStatus;
                    for (let i = 0; i < buffer.length; i++) {
                        dataArray.push(buffer[i]);
                        Object.keys(statuses).forEach((key) => {
                            if (statuses[key].byteIndex === i &&
                                statuses[key].printerResponseValue.includes(dataArray[i])) {
                                printerStatus = statuses[key];
                                commit('setPrinterStatus', printerStatus);
                            }
                        });
                    }
                    if (!printerStatus) {
                        if (dataArray[4] === 0 && dataArray[5] === 0) {
                            printerStatus = statuses.ok;
                        } else {
                            printerStatus = statuses.unknown;
                        }
                        commit('setPrinterStatus', printerStatus);
                    }
                    if (printerStatus.statusClass === "info" || printerStatus.statusClass === "warning") {
                        dispatch("testPrintAllow");
                    } else {
                        dispatch("testPrintFailure");
                    }
                    // set callback to null so this function doesn't get called again
                    window.OnKioDeviceCmdResult = null;
                };
                commit("setPrinterStatusLoading", true);
                const getPaperStatusCommand = [16, 4, 20];
                try {
                    await window.KioCustomSerialDevice.WriteBytes(
                        getPaperStatusCommand,
                        true,
                        null,
                        state.DEVICES.printers.tickets.name
                    );
                } catch (e) {
                    //this catches the error caused by not running the app in Kioware browser
                    commit('setPrinterStatus', state.DEVICES.printers.tickets.statuses.offline);
                    commit("setPrinterStatusLoading", false);
                    window.OnKioDeviceCmdResult = null;
                    dispatch("testPrintFailure");
                }
            },
            async printerCounter ({ commit }) {
                const total = await LocalAdmin.getPrinterCounter();
                commit("setPrinterTotal", total);
            },
            async resetPrinterCounter({state, commit}) {
                const res = await LocalAdmin.resetPrinterCounter(state.PAPER_LENGTH_Mm)
                commit('setPrinterResetResponse', res);
            },
            async fetchPrinterLogs({commit}, requestObj ){
                const res = await LocalAdmin.getPrinterLogs(requestObj)
                commit("setPrinterLogs", res.data);
            }
        },
        getters: {
            getPrinterStatus(state) {
              return  state.status
           },
            getPrinterMaxCoupons(state){
                return state.total.originalCouponCount
            },
            getPrinterCouponsCount(state){
                return state.total.remainingCouponCount
            },
            getPrinterLogs(state){
                return state.printerLogs;
            }
        },
}